import { ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'
import { deserializeAudio } from '../serializers/audio'
import { deserializePurchase } from '@/api_client/api/serializers/purchase'

export default async function cancelPurchaseStripe(
  { client, config }: ApiContext,
  id,
) {
  const response = await client.purchases.stripeCancel(
    AuthHelper.getAccessToken(),
    id,
  )
  if (response.isSuccess()) {
    const success = response.success()
    return deserializePurchase(success)
  } else {
    console.log('getUsers: fail')
    throw response.fail()
  }
}
