import { Folder } from '@/api_client/types/folders'

export const deserializeFolder = (data): Folder => {
  if (data == null) {
    return null
  }

  return {
    id: data.id,
    name: data.name,
    user_id: data.user_id,
    color: data.color,
    count: data.count,
    tags: data.tags,
    created_at: data.created_at,
  }
}
