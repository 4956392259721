import { ApiContext } from '../../types'
import { deserializeAudio } from '@/api_client/api/serializers/audio'
import { Audio } from '@/api_client/types/audios'
import { AuthHelper } from '@/api_client'

export default async function summarizeAudio(
  { client, config }: ApiContext,
  audioId,
  summarize_purpose = '',
  language = '',
): Promise<any> {
  try {
    const bearerToken = AuthHelper.getAccessToken()

    const result = await client.audios.summarize(
      bearerToken.toString(),
      audioId,
      { summarize_purpose, language },
    )

    if (result.isSuccess()) {
      return deserializeAudio(result.success())
    } else {
      return {}
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
