import { ApiContext } from '../../types'
import { User } from '@/api_client/types/user'
import { deserializeUser } from '../serializers/user'

export default async function logIn(
  { client, config }: ApiContext,
  firebaseUid,
  firebaseToken,
): Promise<User> {
  const response = await client.users.login(firebaseUid, firebaseToken)
  if (response.isSuccess()) {
    const payload = response.success()
    return deserializeUser(payload)
  } else {
    console.log('logIn- fail')
    console.log(response.fail())
    throw response.fail()
  }
}
