export const th = {
  sign_in: 'เข้าสู่ระบบ',
  email: 'อีเมล',
  password: 'รหัสผ่าน',
  i_agree_to_toc: 'ฉันยอมรับข้อกำหนดและเงื่อนไข',
  terms_and_conditions: 'ข้อกำหนดและเงื่อนไข',
  privacy_policy: 'นโยบายความเป็นส่วนตัว',
  already_have_an_account: 'มีบัญชีอยู่แล้ว?',
  sign_up: 'สมัครสมาชิก',
  dont_have_an_account: 'ยังไม่มีบัญชี?',
  forgot_password: 'ลืมรหัสผ่าน?',
  email_already_in_use: 'อีเมลถูกใช้ไปแล้ว',
  weak_password: 'รหัสผ่านไม่แข็งแรง',
  too_many_attempts: 'พยายามหลายครั้งเกินไป',
  invalid_email_or_password: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง',
  user_already_registered: 'ผู้ใช้ลงทะเบียนแล้ว',
  unexpected_error: 'ข้อผิดพลาดที่ไม่คาดคิด',
  audios: 'ไฟล์เสียง',
  name: 'ชื่อ',
  auto: 'อัตโนมัติ',
  auto_detect: 'ตรวจจับอัตโนมัติ',
  speaker_num: 'จำนวนผู้พูด',
  speaker_num_reason: 'หากคุณบอกเราจำนวนผู้พูด เราจะให้ผลการถอดเสียงที่ดีขึ้น',
  audio_language: 'ภาษาของไฟล์เสียง',
  your_email: 'อีเมลของคุณ',
  email_result: 'เราจะส่งอีเมลถึงคุณพร้อมผลการถอดเสียง',
  provide_language:
    'หากคุณบอกเราภาษาของไฟล์เสียง เราจะให้ผลการถอดเสียงที่ดีขึ้น',
  upload_audio: 'อัปโหลดไฟล์เสียง',
  upload_file: 'อัปโหลดไฟล์',
  process_audio: 'กำลังประมวลผลไฟล์ของคุณ',
  process_audio_des:
    'กรุณารอสักครู่ขณะไฟล์เสียงของคุณถูกถอดเสียง อาจใช้เวลาสักครู่',
  uploading: 'กำลังอัปโหลด',
  pending: 'รอดำเนินการ',
  uploaded: 'อัปโหลดแล้ว',
  failed: 'ล้มเหลว',
  status: 'สถานะ',
  language: 'ภาษา',
  speakers: 'ผู้พูด',
  duration: 'ระยะเวลา',
  done: 'เสร็จสิ้น',
  close: 'ปิด',
  queuing: 'กำลังเข้าคิว',
  created_at: 'สร้างเมื่อ',
  records: 'บันทึก',
  transcribe: 'ถอดเสียง',
  delete: 'ลบ',
  processing: 'กำลังประมวลผล',
  from_youtube: 'จาก Youtube',
  upload_files: 'อัปโหลดไฟล์',
  place_youtube_link_here: 'วางลิงก์ Youtube ที่นี่',
  submit: 'ส่ง',
  email_notification:
    'ระยะเวลาการทำงานขึ้นอยู่กับความยาวและความซับซ้อนของไฟล์เสียง โดยทั่วไปคุณสามารถคาดหวังผลการถอดเสียงภายในไม่กี่ชั่วโมง เราจะส่งอีเมลแจ้งเตือนเมื่อเสร็จสิ้น',
  filters: 'ตัวกรอง',
  filter: 'กรอง',
  replace: 'แทนที่',
  share_and_export: 'แชร์และส่งออก',
  replace_all: 'แทนที่ทั้งหมด',
  next: 'ถัดไป',
  previous: 'ก่อนหน้า',
  find: 'ค้นหา',
  replace_with: 'แทนที่ด้วย',
  x_of_x: '{current} จาก {total}',
  scroll_lock: 'ล็อกการเลื่อน',
  tags: 'แท็ก',
  bookmark: 'บุ๊กมาร์ก',
  audio: 'เสียง',
  cancel: 'ยกเลิก',
  export: 'ส่งออก',
  export_format: 'รูปแบบการส่งออก',
  copy_text: 'คัดลอกข้อความ',
  monologue: 'การพูดคนเดียว',
  include_timestamps: 'รวมเวลาประทับ',
  include_speaker_names: 'รวมชื่อผู้พูด',
  combine_same_speaker: 'รวมส่วนของผู้พูดเดียวกัน',
  export_mode: 'โหมดการส่งออก',
  no_preview: 'ไม่มีตัวอย่าง',
  copy: 'คัดลอก',
  copied: 'คัดลอกแล้ว',
  unlock_pro_feature: "ปลดล็อกฟีเจอร์นี้โดยอัปเกรดเป็นแผน 'Pro'",
  pro_features: 'ฟีเจอร์ Pro',
  pro_minutes: '1800 นาที / เดือน',
  pro_interact_with_audio: 'โต้ตอบกับเสียง',
  pro_interact_with_audio_des: 'ถามคำถามกับไฟล์เสียงของคุณ',
  pro_summary: 'สรุปด้วย AI',
  pro_summary_des: 'สรุปไฟล์เสียงของคุณ',
  pro_priority: 'คิวลำดับความสำคัญ',
  pro_priority_des: 'ประมวลผลไฟล์เสียงด้วยลำดับความสำคัญ',
  pro_multi_speakers: 'รู้จำผู้พูดหลายคน',
  pro_multi_speakers_des: 'ตรวจจับและระบุผู้พูดหลายคนได้อย่างแม่นยำ',
  redirect_to_stripe: 'คุณจะถูกเปลี่ยนเส้นทางไปที่ Stripe เพื่อชำระเงิน',
  x_per_month: '{x} / เดือน',
  x_per_year: '{x} / ปี',
  trash: 'ถังขยะ',
  dashboard: 'แดชบอร์ด',
  logout: 'ออกจากระบบ',
  are_you_sure_to_delete: 'คุณแน่ใจที่จะลบบันทึกที่เลือก?',
  restore: 'กู้คืน',
  are_you_sure_to_restore: 'คุณแน่ใจที่จะกู้คืนบันทึกที่เลือก?',
  deleted_at: 'ลบเมื่อ',
  free_member_limited_view:
    'สมาชิกฟรีสามารถเข้าถึงได้เฉพาะ {x} นาทีแรกของผลการถอดเสียง',
  hidden_segment: '{x} ส่วนถูกซ่อนหลังจาก {y} นาที',
  settings: 'การตั้งค่า',
  subscription: 'การสมัครสมาชิก',
  default_transcription_language: 'ภาษาการถอดเสียงเริ่มต้น',
  contact_us: 'ติดต่อเรา',
  security: 'ความปลอดภัย',
  about_us: 'เกี่ยวกับเรา',
  download_app: 'ดาวน์โหลดแอป',
  download: 'ดาวน์โหลด',
  app_store: 'App Store',
  play_store: 'Play Store',
  upload_voice: 'อัปโหลดเสียง',
  no_audio_found: 'ไม่พบไฟล์เสียง',
  please_input_valid_email_first: 'กรุณากรอกอีเมลที่ถูกต้องก่อน',
  handling_audio: 'เรากำลังถอดเสียงไฟล์ของคุณ กรุณารอสักครู่',
  welcome_to_leave:
    'คุณสามารถออกจากหน้านี้ได้ เนื่องจากเราจะส่งอีเมลถึงคุณเมื่อผลการถอดเสียงพร้อม',
  send_your_result: 'เราจะส่งอีเมลถึงคุณพร้อมผลการถอดเสียง',
  transcribe_audio_thank:
    'ขอบคุณที่ใช้บริการถอดเสียงของเรา! เรากำลังทำการถอดเสียงไฟล์ของคุณและขอขอบคุณสำหรับความอดทนของคุณ',
  transcribe_audio_no_stay:
    'การถอดเสียงของคุณอาจใช้เวลาสักครู่ แต่ไม่ต้องกังวล คุณไม่จำเป็นต้องอยู่ในหน้านี้ เราจะส่งอีเมลผลการถอดเสียงให้คุณเมื่อเสร็จสิ้น',
  date: 'วันที่',
  sign_up_to_access_more_functions: 'สมัครสมาชิกเพื่อเข้าถึงฟังก์ชันเพิ่มเติม',
  you_can_use_functions: 'คุณสามารถใช้ฟังก์ชันดังต่อไปนี้หลังจากเข้าสู่ระบบ',
  know_more: 'เรียนรู้เพิ่มเติม',
  modify_transcription: 'แก้ไขข้อความถอดเสียง',
  play_audio: 'เล่นเสียง',
  content_filters: 'ตัวกรองเนื้อหา',
  export_diff_type: 'ส่งออกไฟล์ประเภทต่างๆ',
  screenshots: 'ภาพหน้าจอ',
  free_trial: 'ทดลองใช้งานฟรี',
  pricing: 'การตั้งราคา',
  faq: 'คำถามที่พบบ่อย',
  contact: 'ติดต่อ',
  login: 'เข้าสู่ระบบ',
  register: 'สมัครสมาชิก',
  please_login_before_purchase: 'กรุณาเข้าสู่ระบบก่อนทำการซื้อ',
  no_records_found: 'ไม่พบบันทึก',
  x_minutes: '{x} นาที',
  confirm: 'ยืนยัน',
  notification_mobile_app: 'การแจ้งเตือนจากแอปมือถือ',
  notification_mobile_app_des:
    'รับการแจ้งเตือนจากแอปมือถือเมื่อผลการถอดเสียงพร้อม',
  notification_email: 'การแจ้งเตือนทางอีเมล',
  notification_email_des: 'รับการแจ้งเตือนทางอีเมลเมื่อผลการถอดเสียงพร้อม',
  video: 'วิดีโอ',
  or: 'หรือ',
  sign_in_with_google: 'เข้าสู่ระบบด้วย Google',
  sign_in_with_apple: 'เข้าสู่ระบบด้วย Apple',
  login_method: 'วิธีการเข้าสู่ระบบ',
  delete_account: 'ลบบัญชี',
  delete_account_confirm_3_time: 'กดปุ่มลบ 3 ครั้งเพื่อยืนยัน',
  popup_blocked: 'ป๊อปอัปถูกบล็อก',
  account_info: 'ข้อมูลบัญชี',
  redeem: 'แลก',
  redeem_code: 'รหัสแลก',
  summary_des1:
    'บริการสรุปให้บริการสรุปผลการถอดเสียง ซึ่งสามารถสรุปผลการถอดเสียงเป็นประโยคสั้นๆ ได้',
  summary_des2: 'สรุปเก่าของคุณจะถูกแทนที่ด้วยสรุปใหม่',
  consume_transcription_quota: 'ใช้โควตาการถอดเสียง',
  summary: 'สรุป',
  query: 'สอบถาม',
  mins_per_query: '{x} นาทีต่อการสอบถาม',
  free_user_service_once: 'ผู้ใช้ฟรีสามารถใช้บริการนี้ได้เพียงครั้งเดียว',
  free_user_summary:
    'ผู้ใช้ฟรีสามารถใช้บริการสรุปได้เพียงครั้งเดียว กรุณาอัปเกรดเป็น Pro เพื่อสรุปไฟล์เสียงเพิ่มเติม',
  free_user_query:
    'ผู้ใช้ฟรีสามารถใช้บริการสอบถามได้เพียงครั้งเดียว กรุณาอัปเกรดเป็น Pro เพื่อสอบถามเพิ่มเติม',
  information_not_found: 'ไม่พบข้อมูล',
  permission_deny: 'ปฏิเสธสิทธิ์',
  please_login_to_check_the_result: 'กรุณาเข้าสู่ระบบเพื่อตรวจสอบผลลัพธ์',
  speaker_: 'ผู้พูด {x}',
  audio_length: 'ความยาวไฟล์เสียง',
  delete_permanently: 'ลบถาวร',
  are_you_sure_to_delete_permanently: 'คุณแน่ใจที่จะลบถาวรหรือไม่?',
  delete_audios_move_to_bin:
    'บันทึกที่ถูกลบจะถูกย้ายไปที่ถังขยะ บันทึกทั้งหมดในถังขยะจะถูกลบหลังจาก 30 วัน',
  payment_id: 'รหัสการชำระเงิน',
  current_plan: 'แผนปัจจุบัน',
  plan_name: 'ชื่อแผน',
  expire_date: 'วันหมดอายุ',
  subscription_method: 'วิธีการสมัครสมาชิก',
  payment_method: 'วิธีการชำระเงิน',
  payment_history: 'ประวัติการชำระเงิน',
  amount: 'จำนวนเงิน',
  invoice: 'ใบแจ้งหนี้',
  receipt: 'ใบเสร็จ',
  link: 'ลิงก์',
  edit: 'แก้ไข',
  back: 'ย้อนกลับ',
  return: 'กลับ',
  cancel_subscription: 'ยกเลิกการสมัครสมาชิก',
  cancel_subscription_google_play:
    'บนอุปกรณ์ Android ของคุณ > ไปที่ Google Play Store > บัญชี > การชำระเงินและการสมัครสมาชิก > การสมัครสมาชิก > SoundType AI > ยกเลิกการสมัครสมาชิก',
  cancel_subscription_apple_store:
    'บนอุปกรณ์ iOS ของคุณ > ไปที่การตั้งค่า > [ชื่อของคุณ] > การสมัครสมาชิก > SoundType AI > ยกเลิกการสมัครสมาชิก',
  delete_account_des: 'การลบบัญชีจะลบข้อมูลทั้งหมดของคุณอย่างถาวร',
  delete_account_subscription:
    'หากคุณมีการสมัครสมาชิก กรุณายกเลิกการสมัครสมาชิกก่อน',
  keep_subscription: 'เก็บการสมัครสมาชิก',
  cancel_subscription_des:
    'การสมัครสมาชิกของคุณจะถูกยกเลิกเมื่อสิ้นสุดรอบการเรียกเก็บเงินปัจจุบัน ({x}) หลังจากวันที่นั้น คุณจะถูกปรับลดเป็นแผนฟรีและจะไม่สามารถเข้าถึงฟีเจอร์ของแผน Pro ได้อีกต่อไป:',
  cancel_subscription_feature1: 'การบันทึก',
  cancel_subscription_feature1_des:
    'คุณจะสามารถดูได้เฉพาะ {x} นาทีแรกของการบันทึกของคุณ',
  cancel_subscription_feature2: 'เวลาถอดเสียง',
  cancel_subscription_feature2_des:
    'คุณจะสามารถถอดเสียงได้เพียง {x} นาทีต่อเดือน',
  cancel_subscription_count: 'กรุณากดปุ่ม {x} ครั้งเพื่อยืนยัน',
  next_payment_date: 'วันที่ชำระเงินถัดไป',
  basic_plan: 'แผนพื้นฐาน',
  resume_subscription: 'กลับมาสมัครสมาชิกใหม่',
  active: 'ใช้งานอยู่',
  expired: 'หมดอายุ',
  auto_renewal_clause: 'ข้อตกลงการต่ออายุอัตโนมัติ',
  auto_renewal_payment_web:
    'การสมัครสมาชิกของคุณจะต่ออายุอัตโนมัติ เว้นแต่คุณจะยกเลิกอย่างน้อย 24 ชั่วโมงก่อนสิ้นสุดรอบปัจจุบัน บัญชีของคุณจะถูกเรียกเก็บเงินภายใน 24 ชั่วโมงก่อนสิ้นสุดรอบปัจจุบัน คุณสามารถจัดการและยกเลิกการสมัครสมาชิกได้โดยไปที่การตั้งค่า > การเรียกเก็บเงิน',
  auto_renewal_terms_and_conditions:
    'โดยการสมัครสมาชิก คุณยอมรับข้อกำหนดและเงื่อนไขและนโยบายความเป็นส่วนตัวของเรา',
  payment: 'การชำระเงิน',
  monthly: 'รายเดือน',
  annual: 'รายปี',
  minutes_per_month: '{x} นาที/เดือน',
  buy_now: 'ซื้อทันที',
  yearly: 'รายปี',
  yearly_save: 'ประหยัดสูงสุด {x}%',
  pro_plan: 'Pro',
  business_plan: 'ธุรกิจ',
  enterprise_plan: 'องค์กร',
  everything_in_basic: 'ทุกอย่างในแผนพื้นฐาน รวมถึง',
  everything_in_pro: 'ทุกอย่างในแผน Pro รวมถึง',
  everything_in_business: 'ทุกอย่างในแผนธุรกิจ รวมถึง',
  ai_chat_x: 'แชท AI x {x}',
  ai_summary_x: 'สรุป AI x {x}',
  max_x_minutes_per_transcription: 'สูงสุด {x} นาทีต่อการถอดเสียง',
  basic_des2: 'การระบุผู้พูด',
  basic_des3: 'การถอดเสียงไฟล์เสียง/วิดีโอ',
  basic_des4: 'รีวิวและแก้ไขถอดเสียง',
  pro_des2: 'คิวลำดับความสำคัญ',
  pro_des3: 'ส่งออกในรูปแบบ SRT',
  pro_des4: 'สรุปด้วย AI ไม่จำกัด',
  business_des1: 'รายงานการวิเคราะห์การใช้งาน',
  business_des2: 'พื้นที่ทำงานร่วมกันกับสมาชิกทีม',
  business_des3: 'แชร์และแก้ไขถอดเสียงภายในทีมของคุณ',
  business_des4: 'ควบคุมสิทธิ์การแชร์',
  coming_soon: 'เร็วๆ นี้',
  get_started: 'เริ่มต้น',
  bill_annually_x: 'เรียกเก็บเงิน {x} รายปี',
  no_credit_card_required: 'ไม่ต้องใช้บัตรเครดิต',
  save_x: 'ประหยัด {x}%',
  price_per_month: ' / เดือน',
  not_available: 'ไม่พร้อมใช้งาน',
  pricing_title: 'การตั้งราคา',
  upgrade: 'อัปเกรด',
  ask_question_to_your_audio: 'ถามคำถามกับไฟล์เสียงของคุณ',
  monthly_minutes: 'นาทีรายเดือน',
  payment_interval: 'ช่วงการชำระเงิน',
  annually: 'รายปี',
  billing: 'การเรียกเก็บเงิน',
  subscription_plan: 'แผน',
  please_cancel_your_current_subscription_first:
    'กรุณายกเลิกการสมัครสมาชิกปัจจุบันของคุณก่อน',
  please_cancel_your_current_subscription_first_des:
    'ในการเปลี่ยนแผนการสมัครสมาชิก คุณจะต้องยกเลิกการสมัครสมาชิกปัจจุบันของคุณก่อน',
  cancel_subscription_instruction:
    'ไปที่การตั้งค่า > การเรียกเก็บเงิน > ยกเลิกการสมัครสมาชิก',
  website_version: 'เวอร์ชันเว็บไซต์',
  change_to_annual_plan: 'เปลี่ยนเป็นแผนรายปี',
  update_annual_mobile_platform_reminder: 'การเตือนความจำ',
  update_annual_mobile_platform_reminder_des:
    'กรุณาแจ้งว่าคุณต้องยกเลิกการสมัครสมาชิกปัจจุบันบน {x} ก่อนเปลี่ยนเป็นแผนรายปี มิฉะนั้นคุณจะถูกเรียกเก็บเงินสองครั้ง',
  i_understand: 'ฉันเข้าใจ',
  no_credit: 'นาทีไม่พอ',
  re_submit: 'ส่งใหม่อีกครั้ง',
  loading_audio: 'กำลังโหลดไฟล์เสียง',
  your_password: 'รหัสผ่านของคุณ',
  reset_password_email_sent:
    'อีเมลถูกส่งไปยังที่อยู่อีเมลของคุณแล้ว กรุณาตรวจสอบกล่องจดหมายเพื่อรีเซ็ตรหัสผ่าน',
  home: 'หน้าหลัก',
  title_a_z: 'ชื่อ A-Z',
  title_z_a: 'ชื่อ Z-A',
  older_first: 'เก่าก่อน',
  newest_first: 'ใหม่ก่อน',
  table_item_count: '{x} - {y} จาก {z} รายการ',
  starred: 'ติดดาว',
  search: 'ค้นหา',
  create_folder: 'สร้างโฟลเดอร์',
  folder_name: 'ชื่อโฟลเดอร์',
  create: 'สร้าง',
  export_preview: 'ดูตัวอย่างก่อนส่งออก',
  combine_segments: 'รวมส่วน',
  export_audio_only:
    'คุณสามารถส่งออกไฟล์เสียงได้เฉพาะเมื่อไฟล์เสียงกำลังถูกถอดเสียง',
  transcribe_remaining_x_minutes: 'ถอดเสียง {x} นาทีที่เหลือ',
  x_minutes_remain_be_transcribed: 'เหลือ {x} นาทีที่ยังไม่ได้ถอดเสียง',
  free_member_limited_transcribe_view:
    'เฉพาะ {x} นาทีแรกที่ถูกถอดเสียงและสามารถดูได้สำหรับสมาชิกฟรี',
  upgrade_plan_to_transcribe: 'อัปเกรดแผนของคุณเพื่อถอดเสียงไฟล์เสียงทั้งหมด',
  only_paid_user_can_use:
    'เฉพาะผู้ใช้ที่ชำระเงินเท่านั้นที่สามารถถอดเสียงนาทีที่เหลือได้',
  converting_to_audio: 'กำลังแปลงเป็นไฟล์เสียง',
  move_to_folder: 'ย้ายไปยังโฟลเดอร์',
  move_audios_to_folder: 'ย้ายไฟล์เสียงที่เลือกไปยังโฟลเดอร์',
  please_select_folder: 'กรุณาเลือกโฟลเดอร์',
  empty_folder: 'โฟลเดอร์ว่างเปล่า',
  move: 'ย้าย',
  user_not_found:
    'ไม่พบผู้ใช้ ไม่พบผู้ใช้ที่ระบุ โปรดตรวจสอบรายละเอียดและลองอีกครั้ง หรือติดต่อฝ่ายสนับสนุนหากต้องการความช่วยเหลือเพิ่มเติม',
  invalid_link_des:
    'ลิงก์ YouTube ไม่ถูกต้อง รายการเล่น วิดีโอส่วนตัว และวิดีโอที่มีข้อจำกัดด้านอายุไม่ได้รับการสนับสนุน',
  invalid_link: 'ลิงก์ไม่ถูกต้อง',
  translation: 'การแปล',
  translate: 'แปล',
  translate_des1:
    'บริการแปลให้การแปลผลลัพธ์การถอดเสียงด้วย AI สามารถแปลผลลัพธ์การถอดเสียงเป็นภาษาอื่นได้',
  translate_des2: 'ข้อความที่แปลไม่สามารถแก้ไขได้ในขณะนี้',
  translate_des3: 'การแปลเก่าของคุณจะถูกแทนที่ด้วยการแปลใหม่',
  translating: 'กำลังแปล',
  translating_des:
    'กระบวนการแปลกำลังดำเนินการอยู่ กรุณารอสักครู่ เราจะแจ้งให้คุณทราบเมื่อการแปลพร้อมแล้ว',
  please_select_language: 'กรุณาเลือกภาษา',
  display_language: 'ภาษาที่แสดง',
  both: 'ทั้งสอง',
  original: 'ต้นฉบับ',
  translated: 'แปลแล้ว',
}
