import { ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'
import { deserializeAudio } from '../serializers/audio'

export default async function getAudios(
  { client, config }: ApiContext,
  params = {},
) {
  const response = await client.audios.list(AuthHelper.getAccessToken(), params)
  if (response.isSuccess()) {
    const success = response.success()
    const data = success.data
    const meta = success.meta

    // num_pages:1
    // per_page:20
    // total_count:0
    return { data: data.map((item) => deserializeAudio(item)), meta: meta }
  } else {
    console.log('getUsers: fail')
    throw response.fail()
  }
}
