import { ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'
import { deserializeAudio } from '../serializers/audio'

export default async function getAudioUUID(
  { client, config }: ApiContext,
  uuid: string,
) {
  const response = await client.audios.showByUUID(
    AuthHelper.getAccessToken(),
    uuid,
  )
  if (response.isSuccess()) {
    const success = response.success()
    return deserializeAudio(success)
  } else {
    console.log('getAudio: fail')
    throw response.fail()
  }
}
