import { ApiContext } from '../../types'
import { deserializeAudio } from '@/api_client/api/serializers/audio'
import { Audio } from '@/api_client/types/audios'
import { AuthHelper } from '@/api_client'
import { Message } from '@/api_client/types/messages'
import { deserializeMessage } from '@/api_client/api/serializers/message'

export default async function getMessages(
  { client, config }: ApiContext,
  audioId,
): Promise<any> {
  try {
    const bearerToken = AuthHelper.getAccessToken()

    const response = await client.audios.getMessages(
      bearerToken.toString(),
      audioId,
    )

    if (response.isSuccess()) {
      const success = response.success()
      const data = success.data
      const meta = success.meta

      // num_pages:1
      // per_page:20
      // total_count:0
      return { data: data.map((item) => deserializeMessage(item)), meta: meta }
    } else {
      throw new Error(response.error)
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
