import { ApiContext } from '../../types'
import {
  deserializeAudio,
  deserializeSegment,
} from '@/api_client/api/serializers/audio'
import { Audio } from '@/api_client/types/audios'
import { AuthHelper } from '@/api_client'

export default async function generateSignedUrl(
  { client, config }: ApiContext,
  filename: string,
  file_type: string,
): Promise<string> {
  try {
    const bearerToken = AuthHelper.getAccessToken()
    const result = await client.audios.generateSignedUrl(
      bearerToken.toString(),
      filename,
      file_type,
    )
    if (result.isSuccess()) {
      const payload = result.success()
      return payload
    } else {
      console.log(result.fail())
      return null
    }
  } catch (e) {
    console.error(e)
    // throw e
    return null
  }
}
