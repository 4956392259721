import Http from '../Http'
import { IQuery } from '../interfaces/Query'
import { Routes } from '../routes'

export default class Users extends Http {
  public async create(data): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.usersPath(),
      null,
      {},
      data,
    )) as any
  }

  public async update(token, userId, data): Promise<any> {
    return (await this.spreeResponse(
      'put',
      Routes.userPath(userId),
      token,
      {},
      data,
    )) as any
  }

  public async linkProvider(token, userId, data): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.userLinkProviderPath(userId),
      token,
      {},
      data,
    )) as any
  }

  public async login(firebaseUid, firebaseToken): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.userLoginPath(),
      null,
      {},
      { firebase_uid: firebaseUid, firebase_token: firebaseToken },
    )) as any
  }

  public async show(token, id: string, params: IQuery = {}): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.userPath(id),
      token,
      params,
    )) as any
  }

  public async delete(token, id: string, params: IQuery = {}): Promise<any> {
    return (await this.spreeResponse(
      'delete',
      Routes.userPath(id),
      token,
      params,
    )) as any
  }
}
