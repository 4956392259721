import { ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'
import { deserializeAudio } from '../serializers/audio'

export default async function restoreAudios(
  { client, config }: ApiContext,
  ids: number[],
) {
  const response = await client.audios.restore(AuthHelper.getAccessToken(), {
    ids: ids.join(','),
  })
  if (response.isSuccess()) {
    const success = response.success()
    return true
  } else {
    console.log('getUsers: fail')
    throw response.fail()
  }
}
