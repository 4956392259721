import { ApiContext } from '../../types'
import { deserializeAudio } from '@/api_client/api/serializers/audio'
import { Audio } from '@/api_client/types/audios'
import { AuthHelper } from '@/api_client'
import { deserializeUser } from '@/api_client/api/serializers/user'

export default async function deleteUser({
  client,
}: ApiContext): Promise<boolean> {
  try {
    const accessToken = AuthHelper.getAccessToken()
    if (accessToken == null) {
      return null
    }
    const response = await client.users.delete(
      AuthHelper.getAccessToken(),
      AuthHelper.getUserId(),
    )
    if (response.isSuccess()) {
      return true
    } else {
      console.log('getUsers: fail')
      throw response.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
