import { Product } from '@/api_client/types'

export const deserializeProduct = (apiProduct, currency = 'usd'): Product => {
  if (apiProduct == null) {
    return null
  }

  return {
    id: apiProduct.id,
    uid: apiProduct.uid,
    type: apiProduct.type,
    name: apiProduct.name,
    description: apiProduct.description,
    price: apiProduct.price,
    stripe_price_id: apiProduct.stripe_price_id,
    apple_id: apiProduct.apple_id,
    google_id: apiProduct.google_id,
    credits: apiProduct.credits,
    file_per_month: apiProduct.file_per_month,
    created_at: apiProduct.created_at,
    currency_price: apiProduct.prices.find((p) => p.currency === currency),
  }
}
