import { ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'
import { deserializeAudio } from '../serializers/audio'

export default async function restoreFolders(
  { client, config }: ApiContext,
  folderIds: number[],
) {
  const response = await client.folders.restore(
    AuthHelper.getAccessToken(),
    folderIds,
  )
  if (response.isSuccess()) {
    const success = response.success()
    return true
  } else {
    console.log('getUsers: fail')
    throw response.fail()
  }
}
