import { ApiContext } from '../../types'
import { deserializeAudio } from '@/api_client/api/serializers/audio'
import { Audio } from '@/api_client/types/audios'
import { AuthHelper } from '@/api_client'
import { Message } from '@/api_client/types/messages'
import { deserializeMessage } from '@/api_client/api/serializers/message'

export default async function sendMessage(
  { client, config }: ApiContext,
  audioId,
  question,
): Promise<Message> {
  try {
    const bearerToken = AuthHelper.getAccessToken()

    const result = await client.audios.sendMessages(bearerToken.toString(), {
      text: question,
      audio_id: audioId.toString(),
    })

    if (result.isSuccess()) {
      const payload = result.success()
      return deserializeMessage(payload)
    } else {
      throw new Error(result.error)
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
