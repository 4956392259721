import Http from '../Http'
import { IQuery } from '../interfaces/Query'
import { Routes } from '../routes'

export default class Segments extends Http {
  public async update(token: string, id: number, data = {}): Promise<any> {
    return (await this.spreeResponse(
      'put',
      Routes.segmentPath(id),
      token,
      {},
      data,
    )) as any
  }
}
