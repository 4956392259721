import { Audio, Segment, Speaker } from '@/api_client/types/audios'
import { Message } from '@/api_client/types/messages'

export const deserializeMessage = (data): Message => {
  if (data == null) {
    return null
  }

  return {
    id: data.id,
    created_at: data.created_at,
    text: data.text,
    user_id: data.user_id,
  }
}
