import axios from 'axios'
import { IOAuthToken } from '@spree/storefront-api-v2-sdk/types/interfaces/Token'
import { ApiConfig, ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'
import { deserializeUser } from '@/api_client/api/serializers/user'
import { User } from '@/api_client/types/user'

export default async function register(
  { client, config }: ApiContext,
  data,
): Promise<User> {
  const response = await client.users.create(data)
  // console.log('response')
  // console.log(response)
  if (response.isSuccess()) {
    const payload = response.success()
    // console.log(payload)
    return deserializeUser(payload)
  } else {
    throw response.fail()
  }
}
