import Http from '../Http'
import { IQuery } from '../interfaces/Query'
import { Routes } from '../routes'

export default class Tags extends Http {
  public async list(token: string, params: IQuery = {}): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.tagsPath(),
      token,
      params,
    )) as any
  }

  public async update(token: string, id, data): Promise<any> {
    return (await this.spreeResponse(
      'put',
      Routes.tagPath(id),
      token,
      {},
      data,
    )) as any
  }

  public async create(token: string, params: IQuery = {}, data): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.tagsPath(),
      token,
      params,
      data,
    )) as any
  }

  public async delete(token: string, id): Promise<any> {
    return (await this.spreeResponse(
      'delete',
      Routes.tagPath(id),
      token,
    )) as any
  }

  public async addAudios(
    token: string,
    audio_ids: number[],
    tag: string,
  ): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.tagAddAudiosPath(),
      token,
      {},
      { audio_ids: audio_ids, tag: tag },
    )) as any
  }

  public async addFolders(
    token: string,
    folder_ids: number[],
    tag: string,
  ): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.tagAddFoldersPath(),
      token,
      {},
      { folder_ids: folder_ids, tag: tag },
    )) as any
  }

  public async removeAudios(
    token: string,
    audio_ids: number[],
    tag: string,
  ): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.tagRemoveAudiosPath(),
      token,
      {},
      { audio_ids: audio_ids, tag: tag },
    )) as any
  }

  public async removeFolders(
    token: string,
    folder_ids: number[],
    tag: string,
  ): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.tagRemoveFoldersPath(),
      token,
      {},
      { folder_ids: folder_ids, tag: tag },
    )) as any
  }
}
