<template>
  <router-view />
</template>

<script lang="ts">
import { watch } from 'vue'
import { useStore } from 'vuex'

export default {
  setup() {
    const store = useStore()

    watch(store.state, () => {
      store.state.theme === 'dark'
        ? document.body.classList.add('dark-theme')
        : document.body.classList.remove('dark-theme')
    })

    store.state.theme === 'dark'
      ? document.body.classList.add('dark-theme')
      : document.body.classList.remove('dark-theme')
  },
  data() {
    return { isAllowedIPAccess: true }
  },
  // mounted() {
  //   const auth = AuthHelper.getAccessToken()
  //   if (
  //     !window.location.hash.startsWith('#/login') &&
  //     !window.location.hash.startsWith('login#/') &&
  //     (auth == null || auth === '')
  //   ) {
  //     AuthHelper.userLogOut()
  //     window.location.href = '/#/login'
  //   }
  // },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = (to.name || 'Home') + ' | SoundType AI'
      },
    },
  },
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
//@import 'vue-select/src/scss/vue-select.scss';
//@import 'vue-select/dist/vue-select.css';
</style>
