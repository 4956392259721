import moment from 'moment'

const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
})

const filters = {
  currency(input: number, currency: string) {
    if (isNaN(input)) {
      input = 0
    }
    const formatter = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: currency,
    })
    return formatter.format(input)
  },
  // currency(input: number) {
  //   if (isNaN(input)) {
  //     input = 0
  //   }
  //   return formatter.format(input)
  // },
  date(value) {
    if (value) {
      return moment(String(value)).locale('vi').format('YYYY-MMMM-DD hh:mm')
    }
  },
}
export default filters
