import { ApiContext } from '../../types'

import { AuthHelper } from '@/api_client'
import { deserializeUser } from '../serializers/user'

export default async function getUser({ client }: ApiContext) {
  const accessToken = AuthHelper.getAccessToken()
  if (accessToken == null) {
    return null
  }
  const response = await client.users.show(
    AuthHelper.getAccessToken(),
    AuthHelper.getUserId(),
  )
  if (response.isSuccess()) {
    const success = response.success()
    return deserializeUser(success)
  } else {
    console.log('getUsers: fail')
    throw response.fail()
  }
}
