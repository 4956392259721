import { ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'
import { deserializeAudio } from '../serializers/audio'

export default async function removeAudiosFolder(
  { client, config }: ApiContext,
  audioIds: number[],
) {
  console.log('removeAudiosFolder')
  console.log(audioIds)
  const response = await client.folders.removeAudios(
    AuthHelper.getAccessToken(),
    audioIds,
  )
  if (response.isSuccess()) {
    const success = response.success()
    return true
  } else {
    console.log('getUsers: fail')
    throw response.fail()
  }
}
