import home from '@/assets/icons/home.svg'
import pricing from '@/assets/icons/pricing.svg'
import settings from '@/assets/icons/settings.svg'
import downloadRing from '@/assets/icons/download-ring.svg'

export default [
  {
    component: 'CNavItem',
    name: 'home',
    to: '/dashboard',
    icon: home,
  },
  {
    component: 'CNavItem',
    name: 'pricing',
    to: '/plan',
    icon: pricing,
  },
  {
    component: 'CNavItem',
    name: 'settings',
    to: '/settings',
    icon: settings,
  },
  {
    component: 'CNavItem',
    name: 'download_app',
    to: '/download',
    icon: downloadRing,
  },
]
