import Http from '../Http'
import { IProductsResult } from '../interfaces/Product'
import { IQuery } from '../interfaces/Query'
import { Routes } from '../routes'

export default class Products extends Http {
  public async list(
    token: string,
    params: IQuery = {},
  ): Promise<IProductsResult> {
    return (await this.spreeResponse(
      'get',
      Routes.productsPath(),
      token,
      params,
    )) as IProductsResult
  }
}
