import { ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'
import { deserializeAudio } from '../serializers/audio'

export default async function getAudio(
  { client, config }: ApiContext,
  id: number,
  params = {},
) {
  const response = await client.audios.show(
    AuthHelper.getAccessToken(),
    id,
    params,
  )
  if (response.isSuccess()) {
    const success = response.success()
    const data = success.data
    return deserializeAudio(data)
  } else {
    console.log('getUsers: fail')
    throw response.fail()
  }
}
