import { ApiContext } from '../../types'
import {
  deserializeAudio,
  deserializeSegment,
} from '@/api_client/api/serializers/audio'
import { Audio } from '@/api_client/types/audios'
import { AuthHelper } from '@/api_client'

export default async function updateAudio(
  { client, config }: ApiContext,
  audioId,
  data,
): Promise<Audio> {
  try {
    const bearerToken = AuthHelper.getAccessToken()
    const result = await client.audios.update(
      bearerToken.toString(),
      audioId,
      data,
    )

    if (result.isSuccess()) {
      const payload = result.success()
      // console.log(payload)
      return deserializeAudio(payload)
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
