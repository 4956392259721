import { Audio, Segment, Speaker } from '@/api_client/types/audios'
import { deserializeFolder } from '@/api_client/api/serializers/folder'

export const deserializeAudio = (data): Audio => {
  if (data == null) {
    return null
  }
  const segments = data.segments?.map((segment) => {
    return deserializeSegment(segment)
  })
  let speakers = []
  // console.log(data.transcript_speakers)
  // console.log(data.transcript_speakers instanceof Array)

  if (
    data.transcript_speakers?.length == undefined ||
    data.transcript_speakers?.length == 0 ||
    !(data.transcript_speakers instanceof Array)
  ) {
    speakers = []
  } else {
    speakers = data.transcript_speakers.map((segment) => {
      return deserializeSpeaker(segment)
    })
  }

  return {
    audio_file: data.audio_file,
    created_at: data.created_at,
    detected_language: data.detected_language,
    detected_num_of_speakers: data.detected_num_of_speakers,
    email: data.email,
    language: data.language,
    length_seconds: data.length_seconds,
    display_length: formattedTime(data.length_seconds), // format like 00:02
    name: data.name,
    num_of_speakers: data.num_of_speakers,
    processed_duration_seconds: data.processed_duration_seconds,
    segments: segments,
    size_bytes: data.size_bytes,
    status: data.status,
    limited_seconds: data.limited_seconds,
    summary: data.summary,
    summary_at: data.summary_at,
    summary_language: data.summary_language,
    summary_purpose: data.summary_purpose,
    summary_submit_at: data.summary_submit_at,
    transcript_at: data.transcript_at,
    transcript_credit_used: data.transcript_credit_used,
    transcript_speakers: speakers,
    transcript_text: data.transcript_text,
    is_converted: data.is_converted,
    transcription_rating: data.transcription_rating,
    translated_language: data.translated_language,
    updated_at: data.updated_at,
    deleted_at: data.deleted_at,
    user_id: data.user_id,
    uuid: data.uuid,
    youtube_url: data.youtube_url,
    id: data.id,
    cloud_storage_platform: data.cloud_storage_platform,
    tags: data.tags,
    folder: deserializeFolder(data.folder),
  }
}

const formattedTime = (theSeconds) => {
  const minutes = Math.floor(theSeconds / 60)
  const seconds = (theSeconds % 60).toFixed(0)
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds).padStart(2, '0')
  return `${formattedMinutes}:${formattedSeconds}`
}

export const deserializeSegment = (data): Segment => {
  return {
    custom_speaker: data.custom_speaker,
    custom_text: data.custom_text,
    end: data.end,
    id: data.id,
    language: data.language,
    related_id: data.related_id,
    speaker: data.speaker,
    start: data.start,
    tags: data.tags,
    text: data.text,
    translated_text: data.translated_text,
    updated_at: data.updated_at,
    is_text_changed: false,
  }
}
export const deserializeSpeaker = (data): Speaker => {
  return {
    is_show: data.is_show,
    key: data.key,
    name: data.name,
  }
}
