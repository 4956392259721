export const ru = {
  sign_in: 'Войти',
  email: 'Электронная почта',
  password: 'Пароль',
  i_agree_to_toc: 'Я согласен с условиями использования.',
  terms_and_conditions: 'Условия использования',
  privacy_policy: 'Политика конфиденциальности',
  already_have_an_account: 'Уже есть аккаунт?',
  sign_up: 'Зарегистрироваться',
  dont_have_an_account: 'Нет аккаунта?',
  forgot_password: 'Забыли пароль?',
  email_already_in_use: 'Электронная почта уже используется',
  weak_password: 'Слабый пароль',
  too_many_attempts: 'Слишком много попыток',
  invalid_email_or_password: 'Неверная электронная почта или пароль',
  user_already_registered: 'Пользователь уже зарегистрирован',
  unexpected_error: 'Неожиданная ошибка',
  audios: 'Аудио',
  name: 'Имя',
  auto: 'Авто',
  auto_detect: 'Автоопределение',
  speaker_num: 'Количество говорящих',
  speaker_num_reason:
    'Если вы укажете количество говорящих, мы предоставим лучший результат транскрипции.',
  audio_language: 'Язык аудио',
  your_email: 'Ваша электронная почта',
  email_result:
    'Мы отправим вам электронное письмо с результатом транскрипции.',
  provide_language:
    'Если вы укажете язык аудио, мы предоставим лучший результат транскрипции.',
  upload_audio: 'Загрузить аудио',
  upload_file: 'Загрузить файл',
  process_audio: 'Обработка вашего файла',
  process_audio_des:
    'Пожалуйста, подождите, пока ваш аудио файл транскрибируется. Это может занять несколько минут.',
  uploading: 'Загрузка',
  pending: 'В ожидании',
  uploaded: 'Загружено',
  failed: 'Не удалось',
  status: 'Статус',
  language: 'Язык',
  speakers: 'Говорящие',
  duration: 'Продолжительность',
  done: 'Готово',
  close: 'Закрыть',
  queuing: 'В очереди',
  created_at: 'Создано в',
  records: 'Записи',
  transcribe: 'Транскрибировать',
  delete: 'Удалить',
  processing: 'Обработка',
  from_youtube: 'С YouTube',
  upload_files: 'Загрузить файлы',
  place_youtube_link_here: 'Вставьте ссылку на YouTube здесь',
  submit: 'Отправить',
  email_notification:
    'Время выполнения зависит от длины и сложности аудио файла. В общем, вы можете ожидать получения транскрипции в течение нескольких часов. Мы уведомим вас по электронной почте, как только она будет готова.',
  filters: 'Фильтры',
  filter: 'Фильтр',
  replace: 'Заменить',
  share_and_export: 'Поделиться и экспортировать',
  replace_all: 'Заменить все',
  next: 'Далее',
  previous: 'Назад',
  find: 'Найти',
  replace_with: 'Заменить на',
  x_of_x: '{current} из {total}',
  scroll_lock: 'Блокировка прокрутки',
  tags: 'Теги',
  bookmark: 'Закладка',
  audio: 'Аудио',
  cancel: 'Отменить',
  export: 'Экспорт',
  export_format: 'Формат экспорта',
  copy_text: 'Копировать текст',
  monologue: 'Монолог',
  include_timestamps: 'Включить временные метки',
  include_speaker_names: 'Включить имена говорящих',
  combine_same_speaker: 'Объединить сегменты одного говорящего',
  export_mode: 'Режим экспорта',
  no_preview: 'Нет предварительного просмотра',
  copy: 'Копировать',
  copied: 'Скопировано',
  unlock_pro_feature:
    "Чтобы разблокировать эту функцию, перейдите на план подписки 'Pro'.",
  pro_features: 'Pro функции',
  pro_minutes: '1800 минут / месяц',
  pro_interact_with_audio: 'Взаимодействовать с аудио',
  pro_interact_with_audio_des: 'Задавайте вопросы вашему аудио',
  pro_summary: 'AI Сводка',
  pro_summary_des: 'Резюмируйте ваше аудио',
  pro_priority: 'Приоритетная очередь',
  pro_priority_des: 'Обрабатываемое аудио с приоритетом',
  pro_multi_speakers: 'Распознавание нескольких говорящих',
  pro_multi_speakers_des: 'Точно определяет и обозначает различных говорящих',
  redirect_to_stripe:
    'Вы будете перенаправлены на Stripe для завершения оплаты.',
  x_per_month: '{x} / месяц',
  x_per_year: '{x} / год',
  trash: 'Корзина',
  dashboard: 'Панель управления',
  logout: 'Выйти',
  are_you_sure_to_delete: 'Вы уверены, что хотите удалить выбранные записи?',
  restore: 'Восстановить',
  are_you_sure_to_restore:
    'Вы уверены, что хотите восстановить выбранные записи?',
  deleted_at: 'Удалено в',
  free_member_limited_view:
    'Бесплатные пользователи имеют доступ только к первым {x} минутам результата транскрипции.',
  hidden_segment: '{x} сегментов скрыты после {y} минут.',
  settings: 'Настройки',
  subscription: 'Подписка',
  default_transcription_language: 'Язык транскрипции по умолчанию',
  contact_us: 'Свяжитесь с нами',
  security: 'Безопасность',
  about_us: 'О нас',
  download_app: 'Скачать приложение',
  download: 'Скачать',
  app_store: 'App Store',
  play_store: 'Play Store',
  upload_voice: 'Загрузить голос',
  no_audio_found: 'Аудио не найдено',
  please_input_valid_email_first:
    'Пожалуйста, сначала введите действительный адрес электронной почты',
  handling_audio:
    'Мы транскрибируем ваше аудио. Пожалуйста, подождите немного.',
  welcome_to_leave:
    'Вы можете покинуть эту страницу, так как мы отправим вам электронное письмо с результатом транскрипции.',
  send_your_result:
    'Мы отправим вам электронное письмо с результатом транскрипции.',
  transcribe_audio_thank:
    'Спасибо, что воспользовались нашим сервисом транскрипции! Мы работаем над транскрипцией вашего аудио и ценим ваше терпение.',
  transcribe_audio_no_stay:
    'Ваша транскрипция может занять некоторое время, но не беспокойтесь, вам не нужно оставаться на этой странице. Мы отправим вам результаты по электронной почте, как только они будут готовы.',
  date: 'Дата',
  sign_up_to_access_more_functions:
    'Зарегистрируйтесь, чтобы получить доступ к большему количеству функций',
  you_can_use_functions:
    'Вы можете использовать следующие функции после входа в систему',
  know_more: 'Узнать больше',
  modify_transcription: 'Изменить текстовую транскрипцию',
  play_audio: 'Воспроизвести аудио',
  content_filters: 'Фильтры контента',
  export_diff_type: 'Экспортировать различные типы файлов',
  screenshots: 'Скриншоты',
  free_trial: 'Бесплатная пробная версия',
  pricing: 'Цены',
  faq: 'Часто задаваемые вопросы',
  contact: 'Контакт',
  login: 'Вход',
  register: 'Регистрация',
  please_login_before_purchase: 'Пожалуйста, войдите в систему перед покупкой',
  no_records_found: 'Записи не найдены',
  x_minutes: '{x} минут',
  confirm: 'Подтвердить',
  notification_mobile_app: 'Уведомление в мобильном приложении',
  notification_mobile_app_des:
    'Получайте уведомление в мобильном приложении, когда результат транскрипции будет готов',
  notification_email: 'Уведомление по электронной почте',
  notification_email_des:
    'Получайте уведомление по электронной почте, когда результат транскрипции будет готов',
  video: 'Видео',
  or: 'Или',
  sign_in_with_google: 'Войти через Google',
  sign_in_with_apple: 'Войти через Apple',
  login_method: 'Способ входа',
  delete_account: 'Удалить аккаунт',
  delete_account_confirm_3_time:
    'Нажмите кнопку удаления 3 раза для подтверждения',
  popup_blocked: 'Всплывающее окно заблокировано',
  account_info: 'Информация об аккаунте',
  redeem: 'Активировать',
  redeem_code: 'Код активации',
  summary_des1:
    'Сервис сводки предоставляет краткое изложение результата транскрипции. Он может свести результат транскрипции в несколько предложений.',
  summary_des2: 'Ваша старая сводка будет заменена новой сводкой.',
  consume_transcription_quota: 'Потребление квоты транскрипции',
  summary: 'Сводка',
  query: 'Запрос',
  mins_per_query: '{x} минут на запрос',
  free_user_service_once:
    'Бесплатный пользователь может использовать эту услугу только один раз.',
  free_user_summary:
    'Бесплатный пользователь может использовать эту услугу только один раз. Пожалуйста, обновите до Pro, чтобы резюмировать больше аудио.',
  free_user_query:
    'Бесплатный пользователь может использовать эту услугу только один раз. Пожалуйста, обновите до Pro, чтобы задать больше запросов.',
  information_not_found: 'Информация не найдена',
  permission_deny: 'Доступ запрещен',
  please_login_to_check_the_result:
    'Пожалуйста, войдите в систему, чтобы проверить результат',
  speaker_: 'Говорящий {x}',
  audio_length: 'Длина аудио',
  delete_permanently: 'Удалить навсегда',
  are_you_sure_to_delete_permanently:
    'Вы уверены, что хотите удалить навсегда?',
  delete_audios_move_to_bin:
    'Удаленные записи будут перемещены в корзину. Все записи в корзине будут удалены через 30 дней.',
  payment_id: 'ID платежа',
  current_plan: 'Текущий план',
  plan_name: 'Название плана',
  expire_date: 'Дата истечения',
  subscription_method: 'Метод подписки',
  payment_method: 'Способ оплаты',
  payment_history: 'История платежей',
  amount: 'Сумма',
  invoice: 'Счет',
  receipt: 'Квитанция',
  link: 'Ссылка',
  edit: 'Редактировать',
  back: 'Назад',
  return: 'Вернуться',
  cancel_subscription: 'Отменить подписку',
  cancel_subscription_google_play:
    'На вашем устройстве Android > Перейдите в Google Play Store > Аккаунт > Платежи и подписки > Подписки > SoundType AI > Отменить подписку',
  cancel_subscription_apple_store:
    'На вашем устройстве iOS > Перейдите в Настройки > [ваше имя] > Подписки > SoundType AI > Отменить подписку',
  delete_account_des:
    'Удаление аккаунта приведет к удалению всех ваших данных навсегда.',
  delete_account_subscription:
    'Если у вас есть подписка, пожалуйста, сначала отмените ее.',
  keep_subscription: 'Сохранить подписку',
  cancel_subscription_des:
    'Ваша подписка будет отменена в конце текущего расчетного периода ({x}). После этой даты вы будете понижены до бесплатного плана и больше не сможете пользоваться функциями Pro плана:',
  cancel_subscription_feature1: 'Записи',
  cancel_subscription_feature1_des:
    'Вы сможете просматривать только первые {x} минут ваших записей.',
  cancel_subscription_feature2: 'Время транскрипции',
  cancel_subscription_feature2_des:
    'Вы сможете транскрибировать только {x} минут в месяц.',
  cancel_subscription_count:
    'Пожалуйста, нажмите кнопку {x} раз для подтверждения',
  next_payment_date: 'Дата следующего платежа',
  basic_plan: 'Базовый план',
  resume_subscription: 'Возобновить подписку',
  active: 'Активно',
  expired: 'Истекло',
  auto_renewal_clause: 'Клаузула автопродления',
  auto_renewal_payment_web:
    'Ваша подписка будет автоматически продлена, если вы не отмените её как минимум за 24 часа до окончания текущего периода. С вашего аккаунта будет снята плата за продление в течение 24 часов до окончания текущего периода. Вы можете управлять своими подписками и отменять их, перейдя в «Настройки» > «Выставление счетов».',
  auto_renewal_terms_and_conditions:
    'Подписываясь, вы соглашаетесь с нашими Условиями использования и Политикой конфиденциальности.',
  payment: 'Оплата',
  monthly: 'Ежемесячно',
  annual: 'Ежегодно',
  minutes_per_month: '{x} минут/месяц',
  buy_now: 'Купить сейчас',
  yearly: 'Ежегодно',
  yearly_save: 'Экономьте до {x}%',
  pro_plan: 'Pro',
  business_plan: 'Бизнес',
  enterprise_plan: 'Корпоративный',
  everything_in_basic: 'Все в базовом, плюс',
  everything_in_pro: 'Все в Pro, плюс',
  everything_in_business: 'Все в бизнесе, плюс',
  ai_chat_x: 'AI Чат x {x}',
  ai_summary_x: 'AI Сводка x {x}',
  max_x_minutes_per_transcription: 'Макс {x} минут на транскрипцию',
  basic_des2: 'Определение говорящего',
  basic_des3: 'Транскрипция аудио/видео файлов',
  basic_des4: 'Просмотр и редактирование транскрипций',
  pro_des2: 'Приоритетная очередь',
  pro_des3: 'Экспорт в формате SRT',
  pro_des4: 'Неограниченные AI Сводки',
  business_des1: 'Отчет об аналитике использования',
  business_des2: 'Совместное рабочее пространство с членами команды',
  business_des3: 'Поделиться и редактировать транскрипции в вашей команде.',
  business_des4: 'Управление разрешениями на совместное использование',
  coming_soon: 'Скоро будет',
  get_started: 'Начать',
  bill_annually_x: 'Выставляется счет {x} ежегодно',
  no_credit_card_required: 'Кредитная карта не требуется',
  save_x: 'Экономьте {x}%',
  price_per_month: ' / месяц',
  not_available: 'Недоступно',
  pricing_title: 'Цены',
  upgrade: 'Обновить',
  ask_question_to_your_audio: 'Задать вопрос вашему аудио',
  monthly_minutes: 'Ежемесячные минуты',
  payment_interval: 'Интервал оплаты',
  annually: 'Ежегодно',
  billing: 'Выставление счетов',
  subscription_plan: 'План',
  please_cancel_your_current_subscription_first:
    'Пожалуйста, сначала отмените текущую подписку',
  please_cancel_your_current_subscription_first_des:
    'Чтобы изменить план подписки, вам нужно сначала отменить текущую подписку.',
  cancel_subscription_instruction:
    'Перейдите в Настройки > Выставление счетов > Отменить подписку',
  website_version: 'Веб-версия',
  change_to_annual_plan: 'Перейти на годовой план',
  update_annual_mobile_platform_reminder: 'Напоминание',
  update_annual_mobile_platform_reminder_des:
    'Пожалуйста, обратите внимание, что вам нужно отменить текущую подписку на {x} перед переходом на годовой план. В противном случае вам будет выставлен счет дважды.',
  i_understand: 'Я понимаю',
  no_credit: 'Недостаточно минут',
  re_submit: 'Отправить повторно',
  loading_audio: 'Загрузка аудио',
  your_password: 'Ваш пароль',
  reset_password_email_sent:
    'Письмо отправлено на ваш адрес электронной почты. Пожалуйста, проверьте свой почтовый ящик, чтобы сбросить пароль.',
  home: 'Главная',
  title_a_z: 'Заголовок A-Z',
  title_z_a: 'Заголовок Z-A',
  older_first: 'Старые сначала',
  newest_first: 'Новые сначала',
  table_item_count: '{x} - {y} из {z} элементов',
  starred: 'Избранное',
  search: 'Поиск',
  create_folder: 'Создать папку',
  folder_name: 'Имя папки',
  create: 'Создать',
  export_preview: 'Экспорт предпросмотра',
  combine_segments: 'Объединить сегменты',
  export_audio_only:
    'Вы можете экспортировать только аудио, если аудио транскрибируется.',
  transcribe_remaining_x_minutes: 'Транскрибировать оставшиеся {x} минут',
  x_minutes_remain_be_transcribed: 'Осталось {x} минут для транскрипции',
  free_member_limited_transcribe_view:
    'Только первые {x} минут будут транскрибированы и доступны для бесплатных пользователей',
  upgrade_plan_to_transcribe: 'Обновите план для полной транскрипции аудио',
  only_paid_user_can_use:
    'Только платные пользователи могут транскрибировать оставшиеся минуты',
  converting_to_audio: 'Конвертация в аудио',
  move_to_folder: 'Переместить в папку',
  move_audios_to_folder: 'Переместить выбранные аудиофайлы в папку',
  please_select_folder: 'Пожалуйста, выберите папку',
  empty_folder: 'Пустая папка',
  move: 'Переместить',
  user_not_found:
    'Пользователь не найден. Указанный пользователь не может быть найден. Пожалуйста, проверьте данные и попробуйте снова, или обратитесь в службу поддержки, если требуется дополнительная помощь.',
  invalid_link_des:
    'Ссылка на YouTube недействительна. Плейлисты, частные видео и видео с возрастными ограничениями не поддерживаются.',
  invalid_link: 'Недействительная ссылка',
  translation: 'Перевод',
  translate: 'Перевести',
  translate_des1:
    'Сервис перевода предоставляет перевод с помощью ИИ результата транскрипции. Он может перевести результат транскрипции на другой язык.',
  translate_des2: 'Переведенный текст пока нельзя редактировать.',
  translate_des3: 'Ваш старый перевод будет заменен новым переводом.',
  translating: 'Перевод...',
  translating_des:
    'Процесс перевода идет. Пожалуйста, подождите немного. Мы сообщим вам, когда перевод будет готов.',
  please_select_language: 'Пожалуйста, выберите язык',
  display_language: 'Язык отображения',
  both: 'Оба',
  original: 'Оригинал',
  translated: 'Переведено',
}
