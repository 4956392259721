import Http from '../Http'
import { IProductsResult } from '../interfaces/Product'
import { IQuery } from '../interfaces/Query'
import { Routes } from '../routes'

export default class Invoices extends Http {
  public async list(token: string, params: IQuery = {}): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.invoicesPath(),
      token,
      params,
    )) as any
  }
  public async redeemCode(token: string, code): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.purchaseRedeemPath(),
      token,
      {},
      { redeem_code: code },
    )) as IProductsResult
  }
}
