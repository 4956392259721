import { ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'
import { deserializeAudio } from '../serializers/audio'

export default async function addAudiosFolder(
  { client, config }: ApiContext,
  folderId: number,
  audioIds: number[],
) {
  const response = await client.folders.addAudios(
    AuthHelper.getAccessToken(),
    folderId,
    audioIds,
  )
  if (response.isSuccess()) {
    const success = response.success()
    return true
  } else {
    console.log('getUsers: fail')
    throw response.fail()
  }
}
