import { ApiContext } from '../../types'
import {
  deserializeAudio,
  deserializeSegment,
} from '@/api_client/api/serializers/audio'
import { Audio, Segment } from '@/api_client/types/audios'
import { AuthHelper } from '@/api_client'

export default async function updateSegment(
  { client, config }: ApiContext,
  segmentId,
  data,
): Promise<Segment> {
  try {
    const bearerToken = AuthHelper.getAccessToken()
    const result = await client.segments.update(
      bearerToken.toString(),
      segmentId,
      data,
    )

    if (result.isSuccess()) {
      const payload = result.success()
      console.log(payload)
      return deserializeSegment(payload)
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
