<template>
  <CModal
    :visible="visible"
    @close="$emit('close')"
    size="lg"
    alignment="center"
    :keyboard="false"
  >
    <CModalHeader style="color: black">
      <CModalTitle>{{ $t('upload_voice') }}</CModalTitle>
    </CModalHeader>
    <CModalBody style="color: black">
      <UploadAudios :folder_id="folder_id" />
    </CModalBody>
    <!--    <CModalFooter>-->
    <!--      <CButton-->
    <!--        @click="() => $emit('close')"-->
    <!--        style="margin-left: auto; color: black"-->
    <!--      >-->
    <!--        {{ $t('done') }}-->
    <!--      </CButton>-->
    <!--    </CModalFooter>-->
  </CModal>
</template>

<script lang="ts">
import UploadAudios from '@/components/audio/UploadAudios.vue'

export default {
  name: 'UploadAudiosModal',
  components: {
    UploadAudios,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    folder_id: {
      type: String,
      default: '',
    },
  },
  // watch: {
  //   visible(val) {
  //     if (val) {
  //       this.reset_data()
  //     }
  //   },
  // },
  emits: ['close'],
  computed: {
    isMobile() {
      return screen.width <= 760
    },
  },
}
</script>
<style scoped lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  //outline-offset: -10px;
  background: #00000005;
  border-radius: 12px;
  //color: dimgray;
  //padding: 10px 10px;
  min-height: 40px; /* minimum height */
  position: relative;
  cursor: pointer;
  margin-top: 12px;
  margin-bottom: 12px;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 120px;
  position: absolute;
  cursor: pointer;
  z-index: 1;
}

.app-checkbox {
  display: inline-block;
  align-items: center;

  ::v-deep .v-input__control {
    width: fit-content;
    flex-grow: 0;

    .v-input__slot {
      width: fit-content;
    }
  }
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1em;
  text-align: center;
  padding: 40px 0;
}

.page-wrap {
  padding: 0;
}

::v-deep .v-input input {
  height: 40px;
  max-height: 40px;
  padding-left: 12px;
}

::v-deep .v-select__slot {
  height: 40px;
  padding-left: 12px;
}

.input {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 12px;

  ::v-deep .v-input__slot {
    border-radius: 8px !important;
  }

  ::v-deep .v-text-field__details {
    display: none;
  }
}

.title-input {
  label {
    margin-right: 4px;
    margin-bottom: 4px;
  }
}

.section-s {
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  .section-s-title {
    font-size: 1.1em;
    font-weight: 600;
    margin-right: 4px;
  }
}
</style>
