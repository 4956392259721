import { en } from './en'
import { zh } from './zh'
import { de } from '@/i18n/de'
import { es } from '@/i18n/es'
import { fr } from '@/i18n/fr'
import { hi } from '@/i18n/hi'
import { id } from '@/i18n/id'
import { ja } from '@/i18n/ja'
import { ko } from '@/i18n/ko'
import { nl } from '@/i18n/nl'
import { pt } from '@/i18n/pt'
import { vi } from '@/i18n/vi'
import { it } from '@/i18n/it'
import { zh_hans } from '@/i18n/zh_hans'
import { ru } from '@/i18n/ru'
import { th } from '@/i18n/th'
import { tr } from '@/i18n/tr'

export const messages = {
  en,
  de,
  es,
  fr,
  hi,
  id,
  it,
  ja,
  ko,
  nl,
  pt,
  ru,
  th,
  tr,
  vi,
  zh,
  zh_hans,
}
