<template>
  <CHeader position="sticky">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand style="margin-right: auto">
        <!--        <a class="header-logo" href="/">-->
        <!--          <img class="header-logo__img" :src="logo" />SoundType AI-->
        <!--        </a>-->
        <a class="header-logo">{{ $t(pageName) }}</a>
      </CHeaderBrand>
      <!--      <AppBreadcrumb />-->
      <!--      <CHeaderNav class="d-none d-md-flex me-auto">-->
      <!--        <CNavItem>-->
      <!--          <CNavLink href="/dashboard"> Dashboard</CNavLink>-->
      <!--        </CNavItem>-->
      <!--        <CNavItem>-->
      <!--          <CNavLink href="#">Users</CNavLink>-->
      <!--        </CNavItem>-->
      <!--        <CNavItem>-->
      <!--          <CNavLink href="#">Settings</CNavLink>-->
      <!--        </CNavItem>-->
      <!--      </CHeaderNav>-->
      <!--      <CHeaderNav class="ms-auto me-4" v-if="!isTool">-->
      <!--        <CButtonGroup aria-label="Lang switch">-->
      <!--          <CFormCheck-->
      <!--            type="radio"-->
      <!--            :button="{ color: 'light' }"-->
      <!--            name="vi-switch"-->
      <!--            id="btn-vi"-->
      <!--            autoComplete="off"-->
      <!--            :checked="lang === 'vi'"-->
      <!--            @change="langChange('vi')"-->
      <!--          >-->
      <!--            <template #label>-->
      <!--              <div>Vi</div>-->
      <!--            </template>-->
      <!--          </CFormCheck>-->
      <!--          <CFormCheck-->
      <!--            type="radio"-->
      <!--            :button="{ color: 'light' }"-->
      <!--            name="en-switch"-->
      <!--            id="btn-en"-->
      <!--            autoComplete="off"-->
      <!--            :checked="lang === 'en'"-->
      <!--            @change="langChange('en')"-->
      <!--          >-->
      <!--            <template #label>-->
      <!--              <div>En</div>-->
      <!--            </template>-->
      <!--          </CFormCheck>-->
      <!--        </CButtonGroup>-->
      <!--      </CHeaderNav>-->
      <!--      <CHeaderNav class="ms-auto me-4" v-if="false">-->
      <!--        <CButtonGroup aria-label="Theme switch">-->
      <!--          <CFormCheck-->
      <!--            type="radio"-->
      <!--            :button="{ color: 'primary' }"-->
      <!--            name="theme-switch"-->
      <!--            id="btn-light-theme"-->
      <!--            autoComplete="off"-->
      <!--            :checked="$store.state.theme === 'default'"-->
      <!--            @change="-->
      <!--              (event) =>-->
      <!--                $store.commit({-->
      <!--                  type: 'toggleTheme',-->
      <!--                  value: 'default',-->
      <!--                })-->
      <!--            "-->
      <!--          >-->
      <!--            <template #label>-->
      <!--              <CIcon icon="cil-sun" />-->
      <!--            </template>-->
      <!--          </CFormCheck>-->
      <!--          <CFormCheck-->
      <!--            type="radio"-->
      <!--            :button="{ color: 'primary' }"-->
      <!--            name="theme-switch"-->
      <!--            id="btn-dark-theme"-->
      <!--            autoComplete="off"-->
      <!--            :checked="$store.state.theme === 'dark'"-->
      <!--            @change="-->
      <!--              (event) =>-->
      <!--                $store.commit({-->
      <!--                  type: 'toggleTheme',-->
      <!--                  value: 'dark',-->
      <!--                })-->
      <!--            "-->
      <!--          >-->
      <!--            <template #label>-->
      <!--              <CIcon icon="cil-moon" />-->
      <!--            </template>-->
      <!--          </CFormCheck>-->
      <!--        </CButtonGroup>-->
      <!--      </CHeaderNav>-->
      <!--      <CHeaderNav class="ms-3 me-4" v-if="!isTool">-->
      <!--        <AppHeaderDropdownAccnt />-->
      <!--      </CHeaderNav>-->
      <!--      <CHeaderNav class="ms-3 me-4">-->
      <!--        <a href="/#/pos">-->
      <!--          <CButton size="sm" color="primary" style="font-weight: bold"-->
      <!--            >POS-->
      <!--          </CButton>-->
      <!--        </a>-->
      <!--      </CHeaderNav>-->
      <CHeaderNav class="ms-3" v-if="isAudioDetail">
        <!--        <CTooltip :content="$t('scroll_lock')">-->
        <!--          <template #toggler="{ on }">-->
        <!--            <ArrowVerticalLockIcon-->
        <!--              class="header-icon"-->
        <!--              :style="{-->
        <!--                color: $store.state.scrollLock ? null : '#c4c4c4',-->
        <!--              }"-->
        <!--              @click="$store.commit('toggleSegmentScrollLock')"-->
        <!--              v-on="on"-->
        <!--            />-->
        <!--          </template>-->
        <!--        </CTooltip>-->
        <!--        later-->
        <!--        <CTooltip :content="$t('replace')">-->
        <!--          <template #toggler="{ on }">-->
        <!--            <FindReplaceIcon-->
        <!--              class="header-icon"-->
        <!--              @click="$store.commit('toggleSegmentReplace')"-->
        <!--              v-on="on"-->
        <!--            />-->
        <!--          </template>-->
        <!--        </CTooltip>-->
        <!--        <CTooltip :content="$t('filters')">-->
        <!--          <template #toggler="{ on }">-->
        <!--            <FilterVariantIcon-->
        <!--              class="header-icon"-->
        <!--              v-on="on"-->
        <!--              @click="$store.commit('toggleSegmentFilter')"-->
        <!--            />-->
        <!--          </template>-->
        <!--        </CTooltip>-->
        <!--        <CTooltip :content="$t('share_and_export')">-->
        <!--          <template #toggler="{ on }">-->
        <!--            <ShareVariantIcon-->
        <!--              class="header-icon"-->
        <!--              v-on="on"-->
        <!--              @click="$store.commit('toggleSegmentExport')"-->
        <!--            />-->
        <!--          </template>-->
        <!--        </CTooltip>-->
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
// import AppBreadcrumb from './AppBreadcrumb.vue'
import logo from '../assets/logo_blue_small.png'
// import ArrowVerticalLockIcon from 'vue-material-design-icons/ArrowVerticalLock.vue'
import { AuthHelper } from '@/api_client'
import nav from '@/_nav'
// icons end

export default {
  name: 'AppHeader',
  components: {
    // AppBreadcrumb,
    // icons start
    // FilterVariantIcon,
    // FindReplaceIcon,
    // ShareVariantIcon,
    // ArrowVerticalLockIcon,
    // icons end
  },
  created() {
    // console.log('this.$route.name')
    // console.log(this.pageName)
    this.lang = AuthHelper.getDisplayLangCode()
    this.setPageName()
  },
  methods: {
    setPageName() {
      const path = this.$route.path
      if (path === '/trash') {
        this.pageName = 'trash'
        return
      }
      this.pageName =
        nav.find((item) => item.to === this.$route.path)?.name ?? ''
    },
  },
  watch: {
    // call again the method if the route changes
    '$route.path': {
      handler: function () {
        this.isAudioDetail = this.$route.name === 'Audio'
        this.setPageName()
      },
    },
  },
  data() {
    return {
      logo,
      pageName: '',
      lang: null,
      isAudioDetail: this.$route.name === 'Audio',
      // isShow: !this.$route.path.includes('management'),
    }
  },
}
</script>
<style scoped lang="scss">
.header-icon {
  padding: 8px;
}

.header-icon:hover {
  cursor: pointer;
  background-color: #e6e6e6;
}

.header-logo {
  width: 100%;
  margin-left: 8px;
  display: flex;
  align-items: center;
  font-weight: 600;
  text-decoration: none;
  color: #1c1e23;

  &__img {
    width: 30px;
    margin-right: 6px;
  }
}

@media (max-width: 767px) {
  .header-logo {
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;

    &__img {
      width: 24px;
    }
  }
}

.header.header-sticky {
  box-shadow: none;
  background-color: var(--st-background-color);
}

/*.header {*/
/*  display: none !important;*/
/*}*/
</style>
