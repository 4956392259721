import { Audio, Segment, Speaker } from '@/api_client/types/audios'
import { Invoice } from '@/api_client/types/invoices'

export const deserializeInvoice = (data): Invoice => {
  if (data == null) {
    return null
  }

  return {
    id: data.id,
    user_id: data.user_id,
    purchase_id: data.amount,
    status: data.status,
    amount_due: data.amount_due,
    amount_paid: data.amount_paid,
    amount_remaining: data.amount_remaining,
    currency: data.currency,
    transaction_id: data.transaction_id,
    hosted_invoice_url: data.hosted_invoice_url,
    invoice_pdf: data.invoice_pdf,
    purchase_date: data.purchase_date,
    original_purchase_date: data.original_purchase_date,
    transaction_date: data.transaction_date,
    created_at: data.created_at,
    updated_at: data.updated_at,
  }
}
