import { ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'

export default async function getAiServerFee(
  { client, config }: ApiContext,
  audioId,
  type = '',
): Promise<any> {
  try {
    const bearerToken = AuthHelper.getAccessToken()

    const response = await client.audios.getAiServiceRatePath(
      bearerToken,
      audioId,
      {
        type,
      },
    )

    if (response.isSuccess()) {
      return response.success()
    } else {
      // throw new Error(response.error)
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
