import { ApiContext } from '../../types'
import { Audio, Segment } from '@/api_client/types/audios'
import { AuthHelper } from '@/api_client'
import { deserializeUser } from '@/api_client/api/serializers/user'
import { User } from '@/api_client/types/user'

export default async function updateLinkProvider(
  { client, config }: ApiContext,
  userInfo,
): Promise<User> {
  try {
    const bearerToken = AuthHelper.getAccessToken(true)
    const result = await client.users.linkProvider(
      bearerToken.toString(),
      AuthHelper.getUserId(),
      {
        display_name: userInfo.displayName,
        email: userInfo.email,
        phone_number: userInfo.phoneNumber,
        photo_url: userInfo.photoURL,
        provider_id: userInfo.providerId,
        uid: userInfo.uid,
      },
    )

    if (result.isSuccess()) {
      const payload = result.success()
      return deserializeUser(payload)
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
