import { ApiContext } from '../../types'
import { Audio } from '@/api_client/types/audios'
import { AuthHelper } from '@/api_client'
import { deserializeFolder } from '@/api_client/api/serializers/folder'
import { Folder } from '@/api_client/types/folders'

export default async function createFolder(
  { client, config }: ApiContext,
  name: string,
): Promise<Folder> {
  try {
    const bearerToken = AuthHelper.getAccessToken()
    const result = await client.folders.create(
      bearerToken.toString(),
      {},
      { name: name },
    )

    if (result.isSuccess()) {
      const payload = result.success()
      // console.log(payload)
      return deserializeFolder(payload)
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
