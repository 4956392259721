import { ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'
import { deserializeFolder } from '@/api_client/api/serializers/folder'

export default async function getFolders(
  { client, config }: ApiContext,
  params = {},
) {
  // console.log('getFolders')
  // console.log(params)

  const response = await client.folders.list(
    AuthHelper.getAccessToken(),
    params,
  )
  if (response.isSuccess()) {
    const success = response.success()
    const data = success.data
    const meta = success.meta

    // num_pages:1
    // per_page:20
    // total_count:0
    return { data: data.map((item) => deserializeFolder(item)), meta: meta }
  } else {
    console.log('getUsers: fail')
    throw response.fail()
  }
}
