// import { Products, Audios, Users } from './endpoints'

// import Audios from '@/api_client/endpoints/Audios'

import Products from '@/api_client/endpoints/Products'
import Users from '@/api_client/endpoints/Users'
import Audios from '@/api_client/endpoints/Audios'
import Segments from '@/api_client/endpoints/Segments'
import Purchases from '@/api_client/endpoints/Purchases'
import Invoices from '@/api_client/endpoints/Invoices'
import Folders from '@/api_client/endpoints/Folders'
import Tags from '@/api_client/endpoints/Tags'

export interface IClientConfig {
  host?: string
}

class Client {
  public products: Products
  public purchases: Purchases
  public invoices: Invoices
  public users: Users
  public audios: Audios
  public folders: Folders
  public tags: Tags
  public segments: Segments
  protected host?: string

  constructor(config: IClientConfig = {}) {
    this.host = config.host || null
    this.addEndpoints()
  }

  protected addEndpoints() {
    this.audios = this.makeAudios()
    this.products = this.makeProducts()
    this.users = this.makeUsers()
    this.segments = this.makeSegments()
    this.purchases = this.makePurchases()
    this.invoices = this.makeInvoices()
    this.folders = this.makeFolders()
    this.tags = this.makeTags()
  }

  protected makeProducts() {
    return new Products(this.host)
  }

  protected makeAudios() {
    return new Audios(this.host)
  }

  protected makeUsers() {
    return new Users(this.host)
  }

  protected makeSegments() {
    return new Segments(this.host)
  }

  protected makePurchases() {
    return new Purchases(this.host)
  }

  protected makeInvoices() {
    return new Invoices(this.host)
  }

  protected makeFolders() {
    return new Folders(this.host)
  }

  protected makeTags() {
    return new Tags(this.host)
  }
}

export default Client
