import { ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'
import { deserializeAudio } from '../serializers/audio'

export default async function exportFile(
  { client, config }: ApiContext,
  id: number,
  params = {},
) {
  const response = await client.audios.export_file(
    AuthHelper.getAccessToken(),
    id,
    params,
  )
  if (response.isSuccess()) {
    return response.success()
  } else {
    console.log('getUsers: fail')
    throw response.fail()
  }
}
