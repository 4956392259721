import Http from '../Http'
import { IQuery } from '../interfaces/Query'
import { Routes } from '../routes'

export default class Folders extends Http {
  public async show(
    token: string,
    id: number,
    params: IQuery = {},
  ): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.folderPath(id),
      token,
      params,
    )) as any
  }

  public async list(token: string, params: IQuery = {}): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.foldersPath(),
      token,
      params,
    )) as any
  }

  public async update(token: string, id, data): Promise<any> {
    return (await this.spreeResponse(
      'put',
      Routes.folderPath(id),
      token,
      {},
      data,
    )) as any
  }

  public async create(token: string, params: IQuery = {}, data): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.foldersPath(),
      token,
      params,
      data,
    )) as any
  }

  public async addAudios(token: string, id, audio_ids: number[]): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.folderAddAudiosPath(id),
      token,
      {},
      { audio_ids: audio_ids },
    )) as any
  }

  public async removeAudios(token: string, audio_ids: number[]): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.folderRemoveAudiosPath(),
      token,
      {},
      { audio_ids: audio_ids },
    )) as any
  }

  public async remove(
    token: string,
    ids: number[],
    is_permanent = false,
  ): Promise<any> {
    // console.log({ ids: ids.join(','), is_permanent: is_permanent ? '1' : '0' })
    return (await this.spreeResponse(
      'post',
      Routes.folderRemovePath(),
      token,
      {},
      { ids: ids, is_permanent: is_permanent ? '1' : '0' },
    )) as any
  }

  public async restore(token: string, ids: number[]): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.folderRestorePath(),
      token,
      {},
      { ids: ids },
    )) as any
  }
}
