import { ApiContext } from '../../types'
import { Audio, Segment } from '@/api_client/types/audios'
import { AuthHelper } from '@/api_client'
import { deserializeUser } from '@/api_client/api/serializers/user'
import { User } from '@/api_client/types/user'

export default async function updateUser(
  { client, config }: ApiContext,
  data,
): Promise<User> {
  try {
    const bearerToken = AuthHelper.getAccessToken()
    const result = await client.users.update(
      bearerToken.toString(),
      AuthHelper.getUserId(),
      data,
    )

    if (result.isSuccess()) {
      const payload = result.success()
      return deserializeUser(payload)
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
