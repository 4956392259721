import { ApiContext } from '../../types'
import { deserializeAudio } from '@/api_client/api/serializers/audio'
import { Audio } from '@/api_client/types/audios'
import { AuthHelper } from '@/api_client'

export default async function addTagToFolders(
  { client, config }: ApiContext,
  folder_ids: number[],
  tag: string,
): Promise<any> {
  try {
    const bearerToken = AuthHelper.getAccessToken()

    const result = await client.tags.addFolders(
      bearerToken.toString(),
      folder_ids,
      tag,
    )

    if (result.isSuccess()) {
      const payload = result.success()
      console.log(payload)
      return true
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
