import { ApiContext } from '../../types'
import { deserializeAudio } from '@/api_client/api/serializers/audio'
import { Audio } from '@/api_client/types/audios'
import { AuthHelper } from '@/api_client'

export default async function reSubmitAudio(
  { client, config }: ApiContext,
  audio_id: string,
): Promise<Audio> {
  try {
    const result = await client.audios.reSubmit(
      AuthHelper.getAccessToken(),
      audio_id,
    )

    if (result.isSuccess()) {
      const payload = result.success()
      // console.log(payload)
      return deserializeAudio(payload)
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
