<template>
  <div>
    <CButton
      color="dark"
      shape="rounded-pill"
      @click="() => (isShowUploadDialog = true)"
      :style="{ width: isFullWidth ? '100%' : null }"
      class="transcribe-btn"
      :aria-label="$t('transcribe')"
    >
      <span style="margin-right: 12px">{{ $t('transcribe') }}</span>
      <img :src="addRing" />
    </CButton>
    <upload-audios-modal
      :visible="isShowUploadDialog"
      :folder_id="folder_id"
      @close="
        () => {
          isShowUploadDialog = false
        }
      "
    />
  </div>
</template>
<script>
import UploadAudiosModal from '@/components/modals/UploadAudiosModal.vue'
import addRing from '@/assets/icons/add-ring.svg'

export default {
  name: 'TranscribeButton',
  components: { UploadAudiosModal },
  props: {
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    folder_id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      addRing,
      isShowUploadDialog: false,
    }
  },
}
</script>
<style lang="scss" scoped>
.transcribe-btn {
  //background-color: var(--st-dark);
  color: white;
  padding: 8px 24px 8px 24px;
}
</style>
