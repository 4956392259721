import { ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'
import { Message } from '@/api_client/types/messages'
import { deserializeMessage } from '@/api_client/api/serializers/message'

export default async function getMessageResponse(
  { client, config }: ApiContext,
  messageId,
): Promise<Message> {
  try {
    const bearerToken = AuthHelper.getAccessToken()

    const result = await client.audios.getMessageResponse(
      bearerToken.toString(),
      messageId,
    )

    if (result.isSuccess()) {
      const payload = result.success()
      return deserializeMessage(payload)
    } else {
      throw new Error(result.error)
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
