import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CModalTitle = _resolveComponent("CModalTitle")!
  const _component_CModalHeader = _resolveComponent("CModalHeader")!
  const _component_UploadAudios = _resolveComponent("UploadAudios")!
  const _component_CModalBody = _resolveComponent("CModalBody")!
  const _component_CModal = _resolveComponent("CModal")!

  return (_openBlock(), _createBlock(_component_CModal, {
    visible: $props.visible,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
    size: "lg",
    alignment: "center",
    keyboard: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CModalHeader, { style: {"color":"black"} }, {
        default: _withCtx(() => [
          _createVNode(_component_CModalTitle, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('upload_voice')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_CModalBody, { style: {"color":"black"} }, {
        default: _withCtx(() => [
          _createVNode(_component_UploadAudios, { folder_id: $props.folder_id }, null, 8, ["folder_id"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible"]))
}